import React, { useState, useRef } from 'react'
import Blackboard from './components/blackboard/blackboard.jsx'
import './App.css'
//import Canvas from './components/canvas/canvas.jsx'

function App() {
	const canvasRef = useRef(null)
	return (
		<div className='App'>
			<header className='App-header'>
				<h1>Magic Ink proto</h1>
			</header>
			<Blackboard ref={canvasRef} />
			{/*<Canvas/>*/}
		</div>
	)
}

export default App
