import React, {
	forwardRef,
	useRef,
	useEffect,
	useState,
	useImperativeHandle
} from 'react'
import './blackboard.css'

import * as p5 from 'p5'


function Box(sX, sY) {
	let pad = 5
	let offsetOrigenX, offsetOrigenY

	this.sX = sX
	this.sY = sY
	this.offsetX = 0
	this.offsetY = 0
	this.offsetOriginX = 0
	this.offsetOriginY = 0

	this.selected = false
	this.dragging = false  

	/*this.drawSelection = function (s) {
		if(this.selected){
			s.push()  
				s.translate(this.offsetX, this.offsetY)
				s.drawingContext.filter = 'blur(30px)'
				s.stroke("#E3F1FF")
				s.strokeWeight(pad * 20)
				
				s.beginShape()
					for (let i = 0; i < this.sX.length; i++) {
						s.vertex(this.sX[i], this.sY[i])
					}
				s.endShape()
			s.pop()
		}
	}*/

	/*this.drawInk = function (s) {
		if(this.dragging){
			// update position while dragging
			this.offsetX = s.mouseX - this.offsetOriginX
			this.offsetY = s.mouseY - this.offsetOriginY
		}
		s.push()
			s.translate(this.offsetX, this.offsetY)
			// draw ink
			s.noFill()
			s.stroke(0)
			s.strokeWeight(pad * 2)
			s.beginShape()
				for (let i = 0; i < this.sX.length; i++) {
					s.vertex(this.sX[i], this.sY[i])
				}
			s.endShape()
		s.pop()
	}*/

	this.checkCollision = (s) => {
		let tempContactCheck = false

		// check if mouse is near any vertex 
		for (let i = 0; i < this.sX.length; i++) {
			let d = s.dist(this.sX[i], this.sY[i], s.mouseX, s.mouseY)
			
			if(d < pad * 3 ){ // check within the strokeweight
				tempContactCheck = true // check if any of the vertex is near 
			}
		}
		
		// when clicking on paper turn selection off
		if(!tempContactCheck && this.selected){
			console.log("Paper contact")
			this.selected = false
		}

		// when contact on ink turn selection on 
		if(tempContactCheck){
			console.log("Contact")
			this.selected = true 
			this.dragging = true 
			this.offsetOriginX = s.mouseX // store the position once clicked
			this.offsetOriginY = s.mouseY
		}

	}

	this.getCollision = function () {
		if (this.selected) {
			return true
		} else {
			return false
		}
	}

	this.setRelease = function () {

		if(this.dragging){
		// update vertex coordinates in array for x ad y
			for (let i = 0; i < this.sX.length; i++) {
				this.sX[i] = this.sX[i] + this.offsetX
				this.sY[i] = this.sY[i] + this.offsetY  
			}
			this.dragging = false
			console.log("Release")

			this.offsetX = 0
			this.offsetY = 0
		}
	}

	this.setSelected = function () {
		this.selected = true
	}
	this.setDragging = function (){
		this.dragging = true
	}
}

const props = {}

const Blackboard = forwardRef((props, ref) => {
	const drawingCanvas = useRef(null)
	const [sketch, setSketch] = useState(undefined)
	const [strokes, setStrokes] = useState([])

	let boxes = [],
		touchingInk = false,
		selecting = false,
		subSelecting = false,
		penMoving = false,
		subSelectionThreshold = 60,
		strokeW = 10

	let tempX = [],
		tempY = [],
		contactX,
		contactY,
		timer = 0


	// This function could be called before sketch properly initializes,
	// pass down sketch as s to avoid race conditions
	const paintGrids = (s) => {
		s.background(255)
		const unit = 25
		for (let x = 0; x <= window.innerWidth; x += unit) {
			for (let y = 0; y <= s.height; y += unit) {
				s.point(x, y)
				s.stroke('#f6f1ff')
				s.strokeWeight(4)
			}
		}
	}

	const checkInkContact = (s) => {
		boxes.forEach(b => {
			b.checkCollision(s)
		})

		boxes.forEach(b => {
			if (b.getCollision()) {
				touchingInk = true 
				return
			}
		})
		touchingInk = false
	}

	const Sketch = (s) => {
		let currentStrokePoints = []

		s.setup = () => {
			s.createCanvas(window.innerWidth, window.innerHeight - 60)
			paintGrids(s)
		}

		s.draw = () => {

			if (s.mouseIsPressed) {
				if (!touchingInk) {

					console.log(s.mouseX, s.mouseY)
					s.append(tempX, s.mouseX)
					s.append(tempY, s.mouseY)

					s.push()
						s.noFill()
						s.stroke(0)
						s.strokeWeight(strokeW)
						s.beginShape()
							for (let i = 0; i < tempX.length; i++) {
								s.vertex(tempX[i], tempY[i])
							}
						s.endShape()
					s.pop()
				} else {
					timer ++

					if (timer == 1) {

						boxes.forEach(b => {
							b.setSelected()
						})
						contactX = s.mouseX
						contactY = s.mouseY
						subSelecting = false

					} else if (timer == subSelectionThreshold && !penMoving ) {

						checkInkContact(s)
						penMoving = false
						subSelecting = true

					}
				}

				if (s.dist(contactX, contactY, s.mouseX, s.mouseY) > strokeW) {
					penMoving = true
				}
			}

			if (touchingInk && penMoving && !subSelecting) {
				boxes.forEach(b => {
					b.offsetX = s.mouseX - contactX
					b.offsetY = s.mouseY - contactY
				})
			}
		}

		s.mousePressed = () => {
			checkInkContact(s)
		}

		s.mouseReleased = () => {
			touchingInk = false
			penMoving = false
			subSelecting = false

			boxes.forEach(b => {
				b.setRelease()
			})

			if(touchingInk) {

			} else {
				boxes.push(new Box(tempX, tempY))
				tempX = []
				tempY = []
				timer = 0
			}
		}
	}

	useEffect(() => {
		if (!sketch) {
			// Initialize sketch
			const initSketch = new p5(Sketch, drawingCanvas.current)
			setSketch(initSketch)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <div className='blackboard' ref={drawingCanvas}></div>
})

export default Blackboard